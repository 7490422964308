import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  TextBox,
  BannerLinks,
  Breadcrumbs,
  BlogSlider
} from '../../components'

import BannerImage from '../../assets/projektuebersicht.jpg'

import boxes from '../../components/BannerLinks/DefaultBox'
import cards from '../../components/BlogSlider/DefaultCards'

import { generateBreadcrumbsLinks } from '../../utils/index'

const LuxusDesignTrends = () => {
  const links = generateBreadcrumbsLinks('/blog/luxus-design-trends', 'Luxus design trends')
  return (
    <Layout>
      <Helmet
        title="Luxus Design Trends"
        meta={[
          {
            name: 'description',
            content:
              'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut.'
          },
          {
            name: 'keywords',
            content:
              'Luxus Design Trends holzweg, Blog holzweg, Innsbruck, holzweg, Blog, Luxus Design Trends, Design Trends 2019'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Neugierig bleiben
            <br />
            Neues lernen
          </span>
        }
      />
      <Breadcrumbs
        backLinkAlias=' '
        links={links}
      />
      <TextBox
        title="Luxus Design Trends"
        text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut."
      />
      <BlogSlider cards={cards} singleRow={true} />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default LuxusDesignTrends
